<template>
  <div class="foucus">
    <!-- 帖子-->
    <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                 @onRefresh="onRefresh" :error="error" :isNoData="isNoData" :is-higeht-max="true">
      <Post class="post" :postInfo="postInfo" :index="index" v-for="(postInfo,index) in list" :key="getKey(postInfo)"/>
    </PullRefresh>
  </div>
</template>
<script>
import Post from "@/components/Post"
import PullRefresh from "@/components/PullRefresh";
import {followList} from "@/api/community";

export default {
  name: "ComFoucus",
  components: {
    Post,
    PullRefresh
  },
  data() {
    return {
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      pageNumber: 1,
      pageSize: 10,
    }
  },
  computed: {
    isSuccess() {
      return this.$store.state.user.updateCommunityF.isSuccess;
    }
  },
  watch: {
    isSuccess: function (val) {
      if (!val) return;
      this.$store.commit('user/UPDATE_COMMUNITY_F', {isSuccess: false, id: ""})
      let index = this.$store.state.user.commentData.index;
      let id = this.$store.state.user.updateCommunityF.id;
      //先通过评论索引去修改
      if (index >= 0) {
        this.list[index].commentCount++;
        return;
      }
      //如果评论索引没找到，通过id去修改
      if (id) {
        let fIndex = this.list.findIndex(el => el.id === id);
        this.list[fIndex].commentCount++;
      }
    },
    '$store.state.commnity.refreshSta': async function (status) {
      // alert(status)
      if (status) {
        this.list = [];
        await this.onRefresh();
        this.$store.commit('commnity/CHANGE_REFRESHSTA', false);
      }
    }
  },
  created() {
    this.getList('refresh');
  },
  methods: {
    getKey(info) {
      if (info.forWardUser) {
        return info.id + "" + info.forWardUser
      }
      return info.id
    },
    async getList(type) {
      try {
        let req = {
          pageNumber: String(this.pageNumber),
          pageSize: String(this.pageSize),
        }
        let res = await this.$Api(followList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.pageNumber === 1 && this.list.length === 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++
      }
      this.getList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.getList('refresh');
    }
  }
}
</script>
<style lang="scss" scoped>
.foucus {
  height: calc(100% - 44px);
  //overflow-y: auto;

  .post {
    margin-top: 16px;
    padding: 11px 16px 17px 16px;
  }

  .post:first-child {
    margin-top: 0;
  }
}
</style>
